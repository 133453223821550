import React, { useState } from 'react';
import { Container, Board, Input, Button, Logo, Textarea, DateContainer, VersionLable } from './styles';
import LogoLeader from '../../assets/leader_logo.png';
import pagarme from 'pagarme';
import packageJson from '../../../package.json';

const Home = () => {
  
  const [apiKey, setApiKey] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');

  const [cardYear, setCardYear] = useState('');
  const [cardMonth, setCardMonth] = useState('');

  const [hashCard, setHashCard] = useState('');



  const handleCardHashGeneration = () =>{
    if(cardNumber !== '' && ownerName !== '' && cardYear !== '' && cardMonth !== '' && cvv !== ''){
      const card = {
        card_number: cardNumber,
        card_holder_name: ownerName,
        card_expiration_date: `${cardMonth}${cardYear}`,
        card_cvv: cvv,
      }
      pagarme.client.connect({ api_key: apiKey })
      .then(client => client.security.encrypt(card))
      .then(card_hash => {
        setHashCard(card_hash);
        alert('Hash gerado com sucesso!');
      }).catch(err => {
        console.log(err)
        alert('Ops! Verifique se os dados de Cartão estão corretos.')
      });
    }
  };
  
  return (
    <Container>
      <Board>
        <Logo src={LogoLeader}/>
        <Input
          placeholder={'Chave de API'}
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <Input
          placeholder={'Nome do Proprietário do Cartão'}
          value={ownerName}
          onChange={(e) => setOwnerName(e.target.value)}
        />
        <Input
          type={'number'}
          placeholder={'Numero do Cartão'}
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        />
        <Input
          type={'number'}
          placeholder={'CVV'}
          value={cvv}
          onChange={(e) => setCvv(e.target.value)}
        />

        <DateContainer>
          <Input
            type={'number'}
            placeholder={'Mês de Expiração (mm)'}
            value={cardMonth}
            onChange={(e) => setCardMonth(e.target.value)}
            max={2}
          />
          <Input
            type={'number'}
            placeholder={'Ano de Expiração (yy)'}
            value={cardYear}
            onChange={(e) => setCardYear(e.target.value)}
            max={2}
          />
        </DateContainer>

        <Button
          onClick={()=>handleCardHashGeneration()}
        >Gerar Hash de Cartão</Button>

        <Textarea
          rows={"5"}
          disabled
          value={hashCard}
          onChange={(e) => setHashCard(e.target.value)}
        />
        <VersionLable>v {packageJson.version}</VersionLable>
      </Board>
    </Container>
  );
};

export default Home;
