import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Board = styled.div`
  width: 80%;
  /* height: 80%; */
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #000;

  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000;


  margin-top: 15px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
  }

`;

export const Button = styled.button`
    background: #4195e8;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 20px;
    color: #fff;
    width: 100%;
    font-weight: bold;
    margin-top: 16px;
    transition: background-color 0.2s;

    &:active{
        background: ${shade(0.2, '#4195e8')};
    }
`;


export const Logo = styled.img`
  width: 12rem;
  height: 8rem;
  margin-bottom: 50px;
`;


export const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #000;

  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-top: 15px;
  resize: none;
`;


export const DateContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
`;



export const VersionLable = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-top: 20px;

`;